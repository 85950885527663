<template>
  <div>
    <strong>FAQ</strong>
        <ul>
            <li>
                <strong>How do I log in to the app?</strong>
                <p>You can log in to the Expenses app using your Bazooka credentials. If you've
                    forgotten your password it can be reset at <a href="https://bazooka.thinkspatial.com.au/auth/login">https://bazooka.thinkspatial.com.au/auth/login</a>.<br>
                    If you don't have Bazooka credentials yet please contact your system administrator
                </p>
            </li>
            <li>
                <strong>How does it work?</strong>
                <p>
                    Take a picture of your expense receipt, making sure the amount and date are clearly visible. Fill in the date of the
                    receipt, the amount, the category and optionally a short description. After you press submit the expense will be lodged in a 'pending' state
                    until an application admin approves/rejects it. All your lodged expenses can be viewed in the 'My Expenses' tab.
                </p>
            </li>
            <li>
                <strong>I forgot my PIN!</strong>
                <p>No worries. From the enter PIN screen you can select 'Forgotten Pin' and you will be prompted to re-enter your Bazooka details. After you do this you will be able to set a new PIN.</p>
            </li>
            <li>
                <strong>I made a mistake, can I amend one of my expenses?</strong>  
                <p>Yes! As long as your submission is still 'Pending' you can update it. Find your expense in 'My Expenses' and look for the edit icon.</p>
            </li>
            <li>
                <strong>I'm still having issues :(</strong>
                <p>Feel free to shoot me an email at eprice@thinkspatial.com.au and I'll do my best to help you out.</p>
            </li>
        </ul>
  </div>
</template>